import { memo } from 'react';
import './LoadingCat.css';

export const LoadingCat = memo(({
  strLoadingText,
  strWidth='auto',
  strHeight='auto',
  intTextMargin=false,
  bSmall=false,
}) => {
  return (
    <div className='container-lds-cat'>
      <img
        src={`/lds-cat${bSmall ? '-sm' : ''}.svg`}
        alt='A loading spinner in the form of a stretchy swirling cute little cat'
        style={{
          width: strWidth,
          height: strHeight,
        }}
      />
      <p
        style={{
          marginTop: intTextMargin ? `${intTextMargin}rem` : undefined,
          marginBottom: intTextMargin ? `${intTextMargin}rem` : undefined,
        }}
      >
        {strLoadingText || ''}
      </p>
    </div>
  );
});
