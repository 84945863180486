import { useState } from 'react';
import './BurgerButton.css';

export default function BurgerButton({ onClick }) {
  const [bOpen, setOpen] = useState(false);

  return (
    <div
      className={`burger-btn ${bOpen ? 'open' : ''}`}
      onClick={() => {
        setOpen(prev => !prev);
        onClick();
      }}
    >
      <span />
      <span />
      <span />
    </div>
  );
};
