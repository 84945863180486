import {motion, AnimatePresence} from 'framer-motion';
import {useEffect, useState, useRef} from 'react';
import useIsMobile from '../../Hooks/useIsMobile.js';
import ModalBackdrop from '../ModalBackdrop/ModalBackdrop.jsx';
import {LoadingCat} from '../LoadingCat/LoadingCat.jsx';
import './ImageModal.css';

export default function ImageModal({
  objImg,
  callbackClose,
  arrAllImages,
  intIndex,
  bLoading,
  callbackLoadNext,
}) {
  const refImg = useRef();
  const refDescription = useRef();
  const [intDir, setDir] = useState(0);
  const [objCurrImg, setImage] = useState(objImg);
  const [intCurrIndex, setCurrIndex] = useState(intIndex);
  const [bShowDescription, setShowDescription] = useState(false);
  const bIsMobile = useIsMobile();

  const callbackResize = () => {
    if (!refDescription.current || !refImg.current) return;
    refDescription.current.style.width = `${refImg.current.offsetWidth}px`;
    refDescription.current.style.height = `${refImg.current.offsetHeight}px`;
  };

  useEffect(() => {
    const callbackKeypress = e => {
      if (e.keyCode === 27) {
        callbackClose();
      }
    }

    window.addEventListener('resize', callbackResize);
    window.addEventListener('keydown', callbackKeypress);
    return () => {
      window.removeEventListener('resize', callbackResize);
      window.removeEventListener('keydown', callbackKeypress);
    };
  }, []);

  return (
    <ModalBackdrop callbackClose={callbackClose}>
      <motion.div
        onClick={callbackClose}
        className='container-img-modal'
        variants={{
          hidden: {
            scale: 0,
            opacity: 0,
          },
          visible: {
            scale: 1,
            opacity: 1,
          },
          exit: {
            scale: 0,
            opacity: 0,
          },
        }}
        initial='hidden'
        animate='visible'
        exit='exit'
      >
        <button onClick={callbackClose} className='btn-modal btn-close'>
          X
        </button>
        <button
          className='btn-modal btn-left'
          disabled={intCurrIndex === 0}
          onClick={e => {
            e.stopPropagation();
            setDir(-1);
            setImage(arrAllImages[intCurrIndex - 1]);
            setCurrIndex(prev => prev - 1);
          }}
        >
          &lt;
        </button>
        <AnimatePresence exitBeforeEnter custom={intDir}>
          <motion.div
            className='container-img'
            key={objCurrImg.URL}
            custom={intDir}
            initial={dir => ({
              x: dir > 0 ? 1000 : -1000,
              opacity: 0,
            })}
            animate={{
              zIndex: 1,
              x: 0,
              opacity: 1,
            }}
            exit={dir => ({
              zIndex: 0,
              x: dir < 0 ? 1000 : -1000,
              opacity: 0,
            })}
            transition={{
              x: {type: 'spring', stiffness: 300, damping: 30},
              opacity: {duration: 0.2},
            }}
            onAnimationStart={() => {
              if (bIsMobile) {
                setShowDescription(false);
              }
              callbackResize();
            }}
          >
            <h3>{objCurrImg.Title}</h3>
            <div
              className={`container-description ${bIsMobile & bShowDescription ? 'active' : ''}`}
              ref={refDescription}
              onClick={e => {
                e.stopPropagation();
                setShowDescription(prev => !prev);
              }}
            >
              <p>{objCurrImg.Description}</p>
            </div>
            <img
              onClick={e=>e.stopPropagation()}
              src={objCurrImg.URL}
              alt={objCurrImg.Description}
              ref={refImg}
            />
          </motion.div>
        </AnimatePresence>
        {bLoading && intCurrIndex === arrAllImages.length - 1
          ? <div className='container-lds' onClick={e => e.stopPropagation()}>
              <LoadingCat
                bSmall={true}
                strLoadingText={`${!bIsMobile ? 'Fetching more for you...' : ''}`}
              />
            </div>
          : <button
              className='btn-modal btn-right'
              disabled={intCurrIndex === arrAllImages.length - 1}
              onClick={e => {
                e.stopPropagation();
                setDir(1);
                if (intCurrIndex === arrAllImages.length - 1) return;
                if (intCurrIndex === arrAllImages.length - 4) callbackLoadNext();
                setImage(arrAllImages[intCurrIndex + 1]);
                setCurrIndex(prev => prev + 1);
              }}
            >
              &gt;
            </button>
        }
      </motion.div>
    </ModalBackdrop>
  );
};
