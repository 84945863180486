import {memo} from 'react';
import {motion} from 'framer-motion';
import './ImageItem.css';

const c_intDelay = 0.25;

export default memo(function ImageItem({
  callbackGetImage,
  intDelay,
  objImage,
}) {
  console.log('delay is ', intDelay);
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.4,
        type: 'tween',
        delay: intDelay * c_intDelay,
      }}
      className='container-img-item'
    >
      <motion.img
        whileTap={{scale: 0.98}}
        whileHover={{scale: 1.02}}
        className='img-item'
        src={objImage.URL}
        alt={objImage.Description}
        onClick={() => callbackGetImage(objImage)}
      />
    </motion.div>
  );
});
