import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useState, useEffect, useRef, Fragment, useCallback, useLayoutEffect } from 'react';
import axios from 'axios';
import useIsMobile from '../../Hooks/useIsMobile.js';
import { Filters } from '../../Components/Filters/Filters.jsx';
import ImageItem from '../../Components/ImageItem/ImageItem.jsx';
import ImageModal from '../../Components/ImageModal/ImageModal.jsx';
import BurgerButton from '../../Components/BurgerButton/BurgerButton.jsx';
import { LoadingCat } from '../../Components/LoadingCat/LoadingCat.jsx';
import './Work.css';

const c_intImgLimit = 5;

export default function Work({ objVariants, objTransitions }) {
  const bLoadingRef = useRef(false);
  const intTotalCountRef = useRef(0);
  const intCurrImageCount = useRef(0);
  const objUniqueImgsRef = useRef({});

  const [intNumCols, setNumCols] = useState(
    window.innerWidth <= 700
      ? 1
      : window.innerWidth <= 1300
        ? 2
        : 3
  );
  const [arrImageColumns, setImageColumns] = useState([
    {items: [], height: 0, ref: useRef()},
    {items: [], height: 0, ref: useRef()},
    {items: [], height: 0, ref: useRef()},
  ]);
  const [arrAllImages, setAllImages] = useState([]);
  const [objModalImg, setModalImg] = useState(null);
  const [bLoading, setLoading] = useState(true);
  const bIsMobile = useIsMobile();
  const [bHideFilters, setHideFilters] = useState(true);
  const animFilters = useAnimation();
  const [arrAllFilters, setAllFilters] = useState([]);
  const [objQueryParams, setQueryParams] = useState({intPage: 0, strFilter: ''});

  const callbackClickImage = useCallback(objImg => setModalImg(objImg), [setModalImg]);
  const callbackChangeFilters = useCallback(arrSelected => {
    if (bLoadingRef.current) {
      return;
    }
    objUniqueImgsRef.current = {};
    setLoading(true);
    setImageColumns(
      arrImageColumns.map(objCol => ({items: [], height: 0, ref: objCol.ref})),
    );
    setQueryParams({
      intPage: 0,
      strFilter: arrSelected
        .filter(({Selected}) => Selected)
        .map(({Name}) => Name)
        .join(','),
    });
  }, [])

  const fnLoadNextPage = () => {
    if (
      intCurrImageCount.current < intTotalCountRef.current &&
      !bLoadingRef.current
    ) {
      setLoading(true);
      setQueryParams(prev => ({...prev, intPage: prev.intPage + 1}));
    }
  };

  useEffect(() => {
    const callbackResize = () => setNumCols(
      window.innerWidth <= 700
        ? 1
        : window.innerWidth <= 1300
          ? 2
          : 3
    );
    const callbackScroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        fnLoadNextPage();
      }
    };

    window.addEventListener('resize', callbackResize);
    window.addEventListener('scroll', callbackScroll);
    document.getElementById('root').style.overflowY = 'scroll';

    return () => {
      window.removeEventListener('resize', callbackResize);
      window.removeEventListener('scroll', callbackScroll);
      document.getElementById('root').style.overflowY = 'hidden';
    };
  }, []);

  useLayoutEffect(() => {
    if (bLoadingRef.current) {
      return;
    }

    const {intPage, strFilter} = objQueryParams;
    bLoadingRef.current = true;
    axios(
      `api/images?Page=${intPage}&Limit=${c_intImgLimit}${strFilter ? `&Filters=${strFilter}` : ''}`
    )
      .then(({data}) => {
        bLoadingRef.current = false;
        const {TotalCount, AllTags, Data} = data;
        if (!arrAllFilters.length) {
          setAllFilters(AllTags);
        }
        intTotalCountRef.current = TotalCount;

        const intImgsNeededToLoad = {current: Data.length};
        const arrLoadedImages = [];
        for (const objImg of Data) {
          if (objUniqueImgsRef.current[objImg.URL]) {
            intImgsNeededToLoad.current--;
            continue;
          }

          const elImage = new Image();
          elImage.src = objImg.URL;
          elImage.onload = () => {
            if (!arrImageColumns[0].ref.current) {
              return // User navigated to diff page
            }

            const intColWidth = arrImageColumns[0].ref.current.offsetWidth;
            const intOrigImgWidth = elImage.width;
            const flPercentChange = (intOrigImgWidth - intColWidth) / intOrigImgWidth;
            objImg.height = elImage.height - (elImage.height * flPercentChange);

            arrLoadedImages.push(objImg);
            if (arrLoadedImages.length === intImgsNeededToLoad.current) {
              for (let i = 0; i < arrLoadedImages.length; i++) {
                const objImg = arrLoadedImages[i];
                objImg.imgNum = i + 1;

                const objSmallestCol = arrImageColumns
                  .slice(0, intNumCols)
                  .reduce((prev, curr) => (prev.height < curr.height) ? prev : curr);

                objSmallestCol.items.push(objImg);
                objSmallestCol.height += objImg.height;
              }

              setImageColumns(Array.from(arrImageColumns));
              const intMostItemsInCol = arrImageColumns
                .reduce(
                  (prev, curr) => (curr.items.length < prev.items.length) ? prev : curr
                ).items.length;

              const arrAllImages = [];
              for (let i = 0; i < intMostItemsInCol; i++) {
                for (const objCol of arrImageColumns) {
                  if (objCol.items[i]) {
                    arrAllImages.push(objCol.items[i]);
                  }
                }
              }
              intCurrImageCount.current = arrAllImages.length;
              setAllImages(arrAllImages);
              setLoading(false);
            }
          };
        }
      })
      .catch(err => {
        console.dir(err);
        bLoadingRef.current = false;
      });
  }, [objQueryParams]);

  useEffect(() => {
    const arrAllImgs = [];
    for (const objCol of arrImageColumns) {
      arrAllImgs.push(...objCol.items);
      objCol.items = [];
      objCol.height = 0;
    }

    if (!arrAllImgs.length) return;

    const arrImgsToRearrange = Array.from(arrAllImages);
    while (arrImgsToRearrange.length) {
      const arrNextBatch = arrImgsToRearrange.splice(0, 10);
      for (const objImg of arrNextBatch) {
        const objSmallestCol = arrImageColumns
          .slice(0, intNumCols)
          .reduce((prev, curr) => (prev.height < curr.height) ? prev : curr);

        objSmallestCol.items.push(objImg);
        objSmallestCol.height += objImg.height;
      }
    }

    setImageColumns(Array.from(arrImageColumns));
  }, [intNumCols]);

  useLayoutEffect(() => {
    if (bIsMobile) {
      animFilters.start({
        opacity: 0,
        maxHeight: '0rem',
        transition: {
          duration: 0.35,
        }
      });
    } else {
      animFilters.start({
        opacity: 1,
        maxHeight: '10rem',
        transition: {
          duration: 0.35,
        }
      });
    }
  }, [bIsMobile]);

  return (
    <>
      <motion.div
        className='work-section'
        initial='initial'
        animate='in'
        exit='out'
        variants={objVariants}
        transition={objTransitions}
      >
        <div className='container-title'>
          {bIsMobile &&
            <BurgerButton
              onClick={() => {
                if (!bHideFilters) {
                  animFilters.start({
                    opacity: 0,
                    maxHeight: '0rem',
                    transition: {
                      duration: 0.35,
                    }
                  });
                } else {
                  animFilters.start({
                    opacity: 1,
                    maxHeight: '10rem',
                    transition: {
                      duration: 0.35,
                    }
                  });
                }
                setHideFilters(prev => !prev);
              }}
            />
          }
          <h2>My Work</h2>
        </div>
        <motion.div
          className='container-toggle-filters'
          initial={{opacity: 0, maxHeight: '0rem'}}
          animate={animFilters}
          exit={{opacity: 0, maxHeight: '0rem'}}
        >
          <Filters
            arrFilters={arrAllFilters}
            callbackChangeSelected={callbackChangeFilters}
          />
        </motion.div>
        <div className='container-columns'>
          {arrImageColumns.map((objColumn, i) => (
            <div
              className='container-image-column'
              key={i}
              ref={objColumn.ref}
            >
              {objColumn.items.map(objImage => (
                <Fragment key={objImage.URL} >
                  <ImageItem
                    objImage={objImage}
                    callbackGetImage={callbackClickImage}
                    intDelay={objImage.imgNum}
                  />
                </Fragment>
              ))}
            </div>
          ))}
          {bLoading &&
            <LoadingCat strLoadingText='Fetching my latest work for you, just a moment...' />
          }
        </div>
        {bIsMobile &&
          intCurrImageCount.current < intTotalCountRef.current &&
          !bLoadingRef.current &&
            <div className='container-load-more'>
              <button onClick={fnLoadNextPage}>
                Load More
              </button>
            </div>
        }
      </motion.div>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {objModalImg &&
          <ImageModal
            objImg={objModalImg}
            callbackClose={() => setModalImg(null)}
            arrAllImages={arrAllImages}
            intIndex={arrAllImages.findIndex(objImg => objImg.URL === objModalImg.URL)}
            bLoading={bLoading}
            callbackLoadNext={fnLoadNextPage}
            intMax
          />
        }
      </AnimatePresence>
    </>
  );
}
