import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import ObjCommissionOptions from '../../common/CommissionConstants.js';
import useIsMobile from '../../Hooks/useIsMobile.js';
import { LoadingCat } from '../../Components/LoadingCat/LoadingCat.jsx';
import './Commission.css';

const {
  c_objTypeBust,
  c_arrTypeOptions,
  c_objNumSingle,
  c_arrNumOptions,
  c_arrColourOptions,
} = ObjCommissionOptions;

const c_strSectionOverview = 'Overview';
const c_strSectionType = 'Type';
const c_strSectionVisDesc = 'VisualDescription';
const c_strSectionPersDesc = 'PersonalityDescription';
const c_strSectionBackground = 'Background';
const c_strSectionFinalise = 'Finalise';
const c_arrSections = [
  c_strSectionOverview,
  c_strSectionType,
  c_strSectionVisDesc,
  c_strSectionPersDesc,
  c_strSectionBackground,
  c_strSectionFinalise,
];

const c_objSectionTitles = {
  [c_strSectionOverview]: 'Commission',
  [c_strSectionType]: 'Select Type',
  [c_strSectionVisDesc]: 'Visual Description',
  [c_strSectionPersDesc]: 'Personality',
  [c_strSectionBackground]: 'Background',
  [c_strSectionFinalise]: 'Finalise and Submit!',
};

const c_intMaxImages = 6;

const c_framerOptions = {
  initial: dir => ({
    opacity: 0,
    x: `${dir < 0 ? '-' : ''}60vw`,
  }),
  animate: {
    opacity: 1,
    x: '0vw',
  },
  exit: dir => ({
    opacity: 0,
    x: `${dir > 0 ? '-' : ''}60vw`,
  }),
  transition: {
    duration: 0.6,
    type: 'spring',
  },
};

export default function Contact({objVariants, objTransitions}) {
  const bIsMobile = useIsMobile();

  const [bLoadingSpaces, setLoadingSpaces] = useState(true);
  const [intCommSpaces, setCommSpaces] = useState(null);

  const [[strCurrentSection, intDir], setSection] = useState([c_arrSections[0], 1]);
  const intCurrSectionIndex = c_arrSections.indexOf(strCurrentSection);

  const [objSelectedType, setType] = useState(c_objTypeBust);
  const [objSelectedNum, setNum] = useState(c_objNumSingle);

  const [strVisualDescription, setVisualDescription] = useState('');
  const [objFiles, setFiles] = useState({});
  const refVisualDescription = useRef();
  const refFileUpload = useRef();

  const [strPersonalityDescription, setPersonalityDescription] = useState('');

  const [strBackground, setBackground] = useState(c_arrColourOptions[0]);

  const [strUserEmail, setUserEmail] = useState('');
  const refUserEmail = useRef();
  const [strUserName, setUserName] = useState('');
  const refUserName = useRef();
  const [strExtraDetails, setExtraDetails] = useState('');
  const [bCanUseInWork, setCanUseInWork] = useState(false);

  const [strLoadingText, setLoadingText] = useState(null);
  const [bSubmittedRequest, setSubmittedRequest] = useState(false);
  const [bFailedRequest, setFailedRequest] = useState(false);

  useEffect(() => {
    axios({
      url: '/api/spaces',
      method: 'GET',
    })
      .then(({data}) => {
        setCommSpaces(data.Spaces);
      })
      .catch(console.dir);
  }, []);

  useEffect(() => {
    if (intCommSpaces === null) {
      return;
    }

    setLoadingSpaces(false);
  }, [intCommSpaces]);

  const fnValidateSection = () => {
    switch (strCurrentSection) {
      case c_strSectionOverview:
      case c_strSectionType:
      case c_strSectionPersDesc:
      case c_strSectionBackground:
      default:
        return true;
      case c_strSectionVisDesc:
        if (!strVisualDescription) {
          refVisualDescription.current.placeholder = 'You must enter something here!';
          refVisualDescription.current.classList.remove('warning');
          void refVisualDescription.current.offsetWidth;
          refVisualDescription.current.classList.add('warning');
        }
        return strVisualDescription;
      case c_strSectionFinalise:
        if (!strUserEmail) {
          refUserEmail.current.placeholder = 'Please let me know your email!';
          refUserEmail.current.classList.remove('warning');
          void refUserEmail.current.offsetWidth;
          refUserEmail.current.classList.add('warning');
        }
        if (!strUserName) {
          refUserName.current.placeholder = 'Please let me know your name!';
          refUserName.current.classList.remove('warning');
          void refUserName.current.offsetWidth;
          refUserName.current.classList.add('warning');
        }
        return strUserEmail && strUserName;
    }
  };

  const fnSubmitForm = () => {
    setLoadingText(
      `Uploading Images (0 / ${Object.keys(objFiles).length}) This may take a while...`
    );
    setSubmittedRequest(false);
    setFailedRequest(false);
    let intImagesUploaded = 0;
    let intImagesFailed = 0;
    Promise.all(Object.values(objFiles).map(objFile => (
      new Promise((res, rej) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
          axios({
            url: `${process.env.REACT_APP_UPLOAD_URL}?Type=${objFile.type}`,
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: reader.result,
          })
            .then(({data}) => {
              intImagesUploaded++
              setLoadingText(
                `Uploading Images (${intImagesFailed + intImagesUploaded} / ${Object.keys(objFiles).length}) This may take a while...`
              );
              res(data.FileName);
            })
            .catch(e => {
              intImagesFailed++
              rej(e);
            });
        });
        reader.readAsDataURL(objFile);
      })
    )))
      .catch(console.dir)
      .then(arrImageLinks => {
        setLoadingText('Submitting Commission Request...')
        if (!arrImageLinks) {
          setLoadingText(null);
          return;
        };
        axios({
          url: '/api/commission',
          method: 'POST',
          data: {
            Type: {
              Value: objSelectedType.Type,
              Price: objSelectedType.Price,
            },
            Number: {
              Value: objSelectedNum.Type,
              Price: objSelectedNum.Price
            },
            VisualDescription: strVisualDescription,
            PersonalityDescription: strPersonalityDescription,
            ExtraDetails: strExtraDetails,
            FileURLs: arrImageLinks,
            Background: strBackground,
            UserEmail: strUserEmail,
            UserName: strUserName,
            CanUseInWork: bCanUseInWork,
            FailedImages: intImagesFailed,
          }
        })
          .then(() => {
            setLoadingText(null);
            setSubmittedRequest(true);
          })
          .catch(err => {
            setLoadingText(null);
            setFailedRequest(true);
            console.dir(err);
          });
      });
  };

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={objVariants}
      transition={objTransitions}
      className='container-commission-page'
    >
      <h2>{c_objSectionTitles[strCurrentSection]}</h2>
      <div className='comm-section'>
        {bLoadingSpaces &&
          <LoadingCat strLoadingText='Checking availability...' />
        }
        {!bLoadingSpaces &&
          <AnimatePresence exitBeforeEnter custom={intDir}>
            {(() => {
              if (!intCommSpaces) {
                return (
                  <motion.div
                    custom={intDir}
                    {...c_framerOptions}
                    className='container-overview'
                  >
                    <p
                      style={{
                        textAlign: 'center',
                        marginBottom: bIsMobile ? '.3rem' : 0,
                        marginTop: bIsMobile ? undefined : 0,
                      }}
                    >
                      So sorry, but my commission requests are full right now, please check back again soon!
                    </p>
                  </motion.div>
                );
              }

              switch (strCurrentSection) {
                case c_strSectionOverview:
                  return (
                    <motion.div
                      custom={intDir}
                      {...c_framerOptions}
                      className='container-overview'
                    >
                      <p className='text-comm-spaces'>
                        <b>
                          {`${intCommSpaces} space${intCommSpaces > 1 ? 's' : ''} open!`}
                        </b>
                      </p>
                      <p>Thank you for showing interest in commissioning me!</p>
                      <p>Pressing the start button below will take you through the steps of starting a commission with me.</p>
                      <p>Once I receive your request I will respond to you via email and we will continue things from there. You can also email me your commission request directly at abbie.cummings@outlook.com, though using this form is preferable!</p>
                      <p>Payment will be made through paypal and costs are listed in GBP and will be converted to your local currency. We can also arrange alternate payment methods.</p>
                    </motion.div>
                  );
                case c_strSectionType:
                  return (
                    <motion.div
                      custom={intDir}
                      {...c_framerOptions}
                      key={c_strSectionType}
                      className='container-comm-type'
                    >
                      <div>
                        <div className='container-type-btns'>
                          {c_arrNumOptions.map(objNum => (
                            <div className='radio-btn-container' key={objNum.Type}>
                              <label
                                className='custom-radio-btn'
                                onClick={() => setNum(objNum)}
                              >
                                <input
                                  type='radio'
                                  name='num'
                                  checked={objNum.Type === objSelectedNum.Type}
                                  id={objNum.Type}
                                  value={objNum.Type}
                                  onChange={e => e.stopPropagation()}
                                />
                                <span className='radio-dot' />
                              </label>
                              <label className='radio-text' htmlFor={objNum.Type}>
                                {objNum.Display} {objNum.Price ? <span className='price'>(+£{objNum.Price})</span> : ''}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className='container-type-btns'>
                          {c_arrTypeOptions.map(objType => (
                            <div className='radio-btn-container' key={objType.Type}>
                              <label
                                className='custom-radio-btn'
                                onClick={() => setType(objType)}
                              >
                                <input
                                  type='radio'
                                  name='type'
                                  checked={objType.Type === objSelectedType.Type}
                                  id={objType.Type}
                                  value={objType.Type}
                                  onChange={e => e.stopPropagation()}
                                />
                                <span className='radio-dot'/>
                              </label>
                              <label className='radio-text' htmlFor={objType.Type}>
                                {objType.Display} {objType.Price ? <span className='price'>£{objType.Price}</span> : ''}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      {objSelectedType &&
                        <div className='container-ex-img'>
                          <b>Example:</b>
                          <AnimatePresence exitBeforeEnter>
                            <motion.img
                              initial={{opacity: 0}}
                              animate={{opacity: 1}}
                              exit={{opacity: 0}}
                              key={objSelectedType.ExampleURL[objSelectedNum.Type]}
                              src={objSelectedType.ExampleURL[objSelectedNum.Type]}
                              alt={`Example for type "${objSelectedType.Display}"`}
                            />
                          </AnimatePresence>
                        </div>
                      }
                    </motion.div>
                  );
                case c_strSectionVisDesc:
                  return (
                    <motion.div
                      custom={intDir}
                      {...c_framerOptions}
                      key={c_strSectionVisDesc}
                    >
                      <div className='container-vis-description'>
                        <label htmlFor='visual-description'>
                          Enter in the textbox below:
                        </label>
                        <textarea
                          ref={refVisualDescription}
                          id='visual-description'
                          placeholder='Please input a visual description of the character(s) you want me to draw. If there are any details that are important to you or parts that you want emphasised here is the place to let me know!'
                          value={strVisualDescription}
                          onChange={e => setVisualDescription(e.target.value)}
                        />
                      </div>
                      <div className='container-upload-imgs'>
                        <input
                          style={{display: 'none'}}
                          type='file'
                          accept='image/*'
                          onChange={e => {
                            const objNewFiles = {};
                            const arrTooBig = [];
                            const arrTooMany = [];
                            const intExistingFiles = Object.keys(objFiles).length;
                            let intNewFiles = 0;
                            for (const file of e.target.files) {
                              if (file.size > 2000000) {
                                arrTooBig.push(file.name);
                                continue;
                              }

                              if (intExistingFiles + intNewFiles === c_intMaxImages) {
                                arrTooMany.push(file.name);
                                continue;
                              }

                              intNewFiles++;
                              objNewFiles[file.name] = file;
                            }
                            setFiles({
                              ...objFiles,
                              ...objNewFiles,
                            });

                            let strAlert = '';
                            if (arrTooBig.length) {
                              strAlert += `The following images could not be added as they are above 2mb in size:\n\n${arrTooBig.join('\n')}\n\n`;
                            }
                            if (arrTooMany.length) {
                              strAlert += `The following images could not be added as you have reached the maximum number of file uploads:\n\n${arrTooMany.join('\n')}`;
                            }
                            if (strAlert) {
                              alert(strAlert);
                            }
                          }}
                          ref={refFileUpload}
                          multiple
                        />
                        <button
                          onClick={() => refFileUpload.current?.click()}
                          disabled={Object.keys(objFiles).length === 6}
                        >
                          {Object.keys(objFiles).length === c_intMaxImages ? 'Upload Limit Reached' : 'Upload Images'}
                        </button>
                        <div className='container-files'>
                          {Object.keys(objFiles).map(strFileName => (
                            <div className='file-container' key={strFileName}>
                              <b>{strFileName}</b> <span
                                onClick={() => {
                                  delete objFiles[strFileName];
                                  setFiles({...objFiles});
                                }}
                              >X</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  );
                case c_strSectionPersDesc:
                  return (
                    <motion.div
                      custom={intDir}
                      {...c_framerOptions}
                      key={c_strSectionPersDesc}
                    >
                      <div className='container-vis-description'>
                        <label htmlFor='personality-description'>
                          Enter in the textbox below:
                        </label>
                        <textarea
                          id='personality-description'
                          placeholder='(Optional) - Describe any personality features that are important for your character(s) and would like to shine through'
                          value={strPersonalityDescription}
                          onChange={e => setPersonalityDescription(e.target.value)}
                        />
                      </div>
                    </motion.div>
                  );
                case c_strSectionBackground:
                  return (
                    <motion.div
                      custom={intDir}
                      {...c_framerOptions}
                      key={c_strSectionBackground}
                      className='comm-section'
                    >
                      <div className='container-bg-btns'>
                        <div>
                          {c_arrColourOptions.map(strColour => (
                            <div className='radio-btn-container' key={strColour}>
                              <label
                                className='custom-radio-btn'
                                onClick={() => setBackground(strColour)}
                              >
                                <input
                                  type='radio'
                                  name='type'
                                  checked={strColour === strBackground}
                                  id={strColour}
                                  value={strColour}
                                  onChange={e => e.stopPropagation()}
                                />
                                <span className='radio-dot'/>
                              </label>
                              <label className='radio-text' htmlFor={strColour}>
                                {strColour}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  );
                case c_strSectionFinalise:
                  return (
                    <motion.div
                      custom={intDir}
                      {...c_framerOptions}
                      key={c_strSectionFinalise}
                      className='comm-section comm-finalise'
                    >
                      <div className='container-finalise'>
                        <div className='form-section input-section'>
                          <label htmlFor='email'>Your Email:</label>
                          <input
                            ref={refUserEmail}
                            type='text'
                            id='email'
                            value={strUserEmail}
                            onChange={e => setUserEmail(e.target.value)}
                            placeholder={'Please type carefully, or I can\'t reach you!'}
                          />
                        </div>
                        <div className='form-section input-section'>
                          <label htmlFor='name'>Your Name:</label>
                          <input
                            ref={refUserName}
                            type='text'
                            id='name'
                            value={strUserName}
                            onChange={e => setUserName(e.target.value)}
                          />
                        </div>
                        <div className='form-section'>
                          <label htmlFor='extra-details'>Any Extra Details: (Optional)</label>
                          <textarea
                            id='extra-details'
                            value={strExtraDetails}
                            onChange={e => setExtraDetails(e.target.value)}
                          />
                        </div>
                      </div>
                      <div
                        className='container-finalise permission-section'
                        style={
                          strLoadingText
                            ? {marginBottom: 0}
                            : {}
                        }
                      >
                        <div className='radio-btn-container'>
                          <label className='custom-radio-btn'>
                            <input
                              type='checkbox'
                              name='num'
                              onChange={e => {
                                e.stopPropagation();
                                setCanUseInWork(prev => !prev);
                              }}
                            />
                            <span className='checkmark' />
                          </label>
                          <label className='radio-text'>
                            Can I display this commission on the "work" section of this website?
                          </label>
                        </div>

                      </div>
                      {bSubmittedRequest &&
                        <b className='request-status success'>Commission request has been submitted!</b>
                      }
                      {bFailedRequest &&
                        <b className='request-status failed'>Something went wrong :( I'm so sorry for the inconvenience, please try again or email your request directly to abbie.cummings@outlook.com</b>
                      }
                    </motion.div>
                  );
                default:
                  return <></>;
              }
            })()}
          </AnimatePresence>
        }
      </div>
      <div className='navigation-btns'>
        {(() => {
          if (strLoadingText) {
            return <LoadingCat
              strLoadingText={strLoadingText}
              strWidth={bIsMobile ? '6rem' : '8rem'}
              strHeight={bIsMobile ? '6rem' : '8rem'}
            />;
          }
          return (
            <>
              {(intCurrSectionIndex > 0 && !bSubmittedRequest) &&
                <button
                  onClick={() => {
                    if (intCurrSectionIndex === 0) {
                      return;
                    }
                    setSection([c_arrSections[intCurrSectionIndex - 1], -1]);
                  }}
                >
                  Back
                </button>
              }
              {(intCommSpaces || undefined) &&
                <button
                  onClick={() => {
                    if (bSubmittedRequest) {
                      setSubmittedRequest(false);
                      setFailedRequest(false);
                      setType(c_objTypeBust);
                      setNum(c_objNumSingle);
                      setVisualDescription('');
                      setFiles({});
                      setPersonalityDescription('');
                      setBackground(c_arrColourOptions[0]);
                      setUserEmail('');
                      setUserName('');
                      setExtraDetails('');
                      setCanUseInWork(false);
                      return setSection([c_arrSections[1], 1]);
                    }

                    if (!fnValidateSection()) return;

                    if (intCurrSectionIndex === c_arrSections.length - 1) {
                      return fnSubmitForm();
                    }
                    setSection([c_arrSections[intCurrSectionIndex + 1], 1]);
                  }}
                >
                  {intCurrSectionIndex === 0
                    ? 'Start'
                    : bSubmittedRequest
                      ? 'Start a New Request'
                      : intCurrSectionIndex === c_arrSections.length - 1
                        ? 'Submit'
                        : 'Next'
                  }
                </button>
              }
            </>
          );
        })()}
      </div>
    </motion.div>
  )
};
