import {memo} from 'react';
import {AnimateSharedLayout, motion} from 'framer-motion';
import {useLocation, withRouter} from 'react-router-dom';
import './Header.css';

const c_arrPaths = [
  {rgx: /^\/(work)?$/, pathname: '/work', text: 'Work'},
  {rgx: /\/commission/, pathname: '/commission', text: 'Commission'},
  {rgx: /\/about/, pathname: '/about', text: 'About'},
  {rgx: /\/contact/, pathname: '/contact', text: 'Contact'},
];

export default withRouter(memo(function Header({history}) {
  const objLocation = useLocation();

  return (
    <header>
      <h1>Tami Art</h1>
      <nav>
        <AnimateSharedLayout>
          <ul>
            {c_arrPaths.map(objPath => (
              <li
                key={objPath.pathname}
                className={objPath.rgx.test(objLocation.pathname) ? 'active' : ''}
              >
                <button className='btn-link' onClick={() => {
                  window.scrollTo({top: 0, behavior: 'smooth'});
                  let intAttempts = 0;
                  const intIntervalKey = setInterval(() => {
                    intAttempts++;
                    if (intAttempts > 20 || window.scrollY === 0) {
                      clearInterval(intIntervalKey);
                      history.push(objPath.pathname);
                    }
                  }, 50);
                }}>
                  <p>{objPath.text}</p>
                  {objPath.rgx.test(objLocation.pathname) &&
                    <motion.div
                      transition={{
                        duration: 0.8,
                        ease: 'easeInOut',
                      }}
                      className='underline'
                      layoutId='underline'
                    />
                  }
                </button>
              </li>
            ))}
          </ul>
        </AnimateSharedLayout>
      </nav>
    </header>
  );
}));
