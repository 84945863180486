import { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import useIsMobile from '../../Hooks/useIsMobile.js';
import { LoadingCat } from '../../Components/LoadingCat/LoadingCat.jsx';
import './Contact.css';

export default function Contact({objVariants, objTransitions}) {
  const bIsMobile = useIsMobile();

  const [strName, setName] = useState('');
  const [strEmail, setEmail] = useState('');
  const [strContent, setContent] = useState('');
  const refName = useRef();
  const refEmail = useRef();
  const refContent = useRef();

  const refRecaptcha = useRef();
  const [strRecaptchaToken, setRecaptchaToken] = useState('');

  const [bIsLoading, setIsLoading] = useState(false);
  const [strRequestError, setRequestError] = useState(false);
  const [bShowSuccessText, setShowSuccessText] = useState(false);

  const fnHandleSubmit = () => {
    if (!strName) {
      refName.current.placeholder = 'Please fill in your name';
      refName.current.classList.remove('warning');
      void refName.current.offsetWidth;
      refName.current.classList.add('warning');
    }

    if (!strEmail) {
      refEmail.current.placeholder = 'Please fill in your name';
      refEmail.current.classList.remove('warning');
      void refEmail.current.offsetWidth;
      refEmail.current.classList.add('warning');
    }

    if (!strContent) {
      refContent.current.placeholder = 'Please fill in your name';
      refContent.current.classList.remove('warning');
      void refContent.current.offsetWidth;
      refContent.current.classList.add('warning');
    }

    if (!strName || !strEmail || !strContent) return;

    if (!strRecaptchaToken) {
      return setRequestError('Please click the ReCaptcha checkbox');
    }

    setIsLoading(true);
    setRequestError('');
    setShowSuccessText(false);

    axios({
      url: '/api/email',
      method: 'POST',
      data: {
        FromName: strName,
        FromEmail: strEmail,
        MessageBody: strContent,
        RecaptchaToken: strRecaptchaToken,
      },
    })
      .then(() => {
        setIsLoading(false);
        setShowSuccessText(true);
        setRecaptchaToken('');
        refRecaptcha.current.reset();
      })
      .catch(e => {
        setIsLoading(false);
        setRecaptchaToken('');
        refRecaptcha.current.reset();
        if (e?.response?.status === 429) {
          return setRequestError('Please wait before sending another email');
        }
        if (e?.response?.status === 401) {
          return setRequestError('Recaptcha has been unable to verify that you\'re human. Please email me directly at abbie.cummings@outlook.com')
        }
        return setRequestError(
          'Something went wrong, please try again or if the error persists ' +
          'feel free to email me directly at abbie.cummings@outlook.com'
        );
      });
  };

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={objVariants}
      transition={objTransitions}
      className='contact-container'
    >
      <h2>Contact me</h2>
      <div className='container-form'>
        <form>
          <div className='input-block'>
            <label htmlFor='name-input'>Your Name:</label>
            <input
              ref={refName}
              name='name-input'
              value={strName}
              onChange={e => setName(e.target.value)}
              type='text'
            />
          </div>
          <div className='input-block'>
            <label htmlFor='email-input'>Your Email:</label>
            <input
              ref={refEmail}
              name='email-input'
              value={strEmail}
              onChange={e => setEmail(e.target.value)}
              type='text'
              placeholder={'Please type carefully or I can\'t reach you!'}
            />
          </div>
          <div className='area-block'>
            <label htmlFor='message-input'>Message Content:</label>
            <textarea
              ref={refContent}
              name='message-input'
              value={strContent}
              onChange={e => setContent(e.target.value)}
            />
          </div>
          <div className='container-btn'>
            {bIsLoading
              ? <LoadingCat
                  strLoadingText='Sending email...'
                  strWidth={bIsMobile ? '6rem' : '8rem'}
                  strHeight={bIsMobile ? '6rem' : '8rem'}
                  intTextMargin={0.3}
                />
              : <div className='container-captcha-submit'>
                  <button
                    type='button'
                    onClick={fnHandleSubmit}
                    style={{
                      display: strRecaptchaToken ? 'block' : 'none'
                    }}
                  >
                    Submit
                  </button>
                  <div
                    className='container-recaptcha'
                    style={
                      bIsMobile
                        ? {
                            display: strRecaptchaToken ? 'none' : 'block'
                          }
                        : {
                            display: strRecaptchaToken ? 'none' : 'block'
                          }
                    }
                  >
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      ref={refRecaptcha}
                      onChange={setRecaptchaToken}
                    />
                  </div>
                </div>
            }
            {strRequestError &&
              <p className='text-error'>
                <b>{strRequestError}</b>
              </p>
            }
            {bShowSuccessText &&
              <p className='text-success'>
                <b>Email sent! I will respond as soon as possible</b>
              </p>
            }
          </div>
        </form>
      </div>
    </motion.div>
  )
};
