const c_intPriceSingle = 0;
const c_intPriceDouble = 5;
const c_objNumSingle = {
    Type: 'Single',
    Display: 'One Character',
    Price: c_intPriceSingle,
};
const c_objNumDouble = {
    Type: 'Double',
    Display: `Two Characters`,
    Price: c_intPriceDouble,
};
const c_arrNumOptions = [
    c_objNumSingle,
    c_objNumDouble,
];

const c_intBustPrice = 3;
const c_intHalfPrice = 10;
const c_intFullPrice = 15;
const c_objTypeBust = {
    Type: 'Bust',
    Display: `Bust (Sketch)`,
    Price: c_intBustPrice,
    ExampleURL: {
        [c_objNumSingle.Type]: '/sketch.png',
        [c_objNumDouble.Type]: '/sketch.png',
    },
};
const c_objTypeHalf = {
    Type: 'Half',
    Display: `Half Body (Colour And Shading)`,
    Price: c_intHalfPrice,
    ExampleURL: {
        [c_objNumSingle.Type]: '/1-half.png',
        [c_objNumDouble.Type]: '/2-half.png',
    },
};
const c_objTypeFull = {
    Type: 'Full',
    Display: `Full Body (Colour And Shading)`,
    Price: c_intFullPrice,
    ExampleURL: {
        [c_objNumSingle.Type]: '/1-full.png',
        [c_objNumDouble.Type]: '/2-full.png',
    },
};
const c_arrTypeOptions = [
    c_objTypeBust,
    c_objTypeHalf,
    c_objTypeFull,
];

const c_arrColourOptions = [
    'Red',
    'Orange',
    'Yellow',
    'Green',
    'Blue',
    'Purple',
    'Pink',
    'Black',
    'White',
    'Grey',
    'Transparent',
];

const CommisionOptions = {
    c_objTypeBust,
    c_objTypeHalf,
    c_objTypeFull,
    c_arrTypeOptions,
    c_objNumSingle,
    c_objNumDouble,
    c_arrNumOptions,
    c_arrColourOptions,
};

export default CommisionOptions;
