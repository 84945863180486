import {useEffect, useState} from 'react';

export default function useIsMobile() {
  const [bIsMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const callbackResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', callbackResize);
    return () => window.removeEventListener('resize', callbackResize);
  }, []);

  return bIsMobile;
};
