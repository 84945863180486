import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './Components/Header/Header.jsx';
import About from './Routes/About/About.jsx';
import Work from './Routes/Work/Work.jsx';
import Commission from './Routes/Commission/Commission.jsx';
import Contact from './Routes/Contact/Contact.jsx';
import './App.css';

// Page transitions
const c_intPageTransitionTime = 0.8;
const c_objTransitionVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const c_objTransitions = {
  duration: c_intPageTransitionTime / 2,
  ease: 'easeInOut'
};

// preload images
[
  '/ex-bust.jpg',
  '/ex-full.jpg',
  '/ex-half.jpg',
  '/lds-cat-new.svg',
  '/lds-cat-sm.svg',
  '/lds-cat.svg',
]
  .forEach(strUrl => new Image().src = strUrl);


export default function App() {
  const objLocation = useLocation();

  return (
      <>
        <Header />
        <main>
          <AnimatePresence exitBeforeEnter>
            <Switch location={objLocation} key={objLocation.pathname}>
              <Route path='/work' render={() =>
                <Work objVariants={c_objTransitionVariants} objTransitions={c_objTransitions}/>
              }/>
              <Route path='/about' render={() =>
                <About objVariants={c_objTransitionVariants} objTransitions={c_objTransitions}/>
              } />
              <Route path='/contact' render={() =>
                <Contact objVariants={c_objTransitionVariants} objTransitions={c_objTransitions}/>
              } />
              <Route path='/commission' render={() =>
                <Commission objVariants={c_objTransitionVariants} objTransitions={c_objTransitions} />
              } />
              <Route path='/' render={() =>
                <Work objVariants={c_objTransitionVariants} objTransitions={c_objTransitions}/>
              } />
            </Switch>
          </AnimatePresence>
        </main>
      </>
  );
};
