import { motion } from 'framer-motion';
import './About.css';

export default function About({objVariants, objTransitions}) {
  return (
    <motion.div
        initial='initial'
        animate='in'
        exit='out'
        className='container-about'
        variants={objVariants}
        transition={objTransitions}
    >
      <h2>About Me</h2>
      <div className='container-text'>
        <p>Hello, my name is Tami and thank you for visiting my website! <br /><br/>
I am a self taught artist, comfortable in traditional art using markers and digital art in the program Paint Tool Sai. I specialise in drawing pretty girls and furries but can also draw cute boys and regular animals. I will not draw mechanical things (unless as a small part of a design) or any type or porn or fetish art. <br/><br/>
Please enjoy your stay! :^)</p>
      </div>
    </motion.div>
  );
};
