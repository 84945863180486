import {motion, AnimatePresence} from 'framer-motion';
import {Fragment, useState, useRef, useEffect, memo} from 'react';
import './Filters.css';

export const Filters = memo(function({arrFilters, callbackChangeSelected}) {
  const [arrSelected, setSelected] = useState([]);
  const refFirstRender = useRef(true);
  const objDisabledBtns = useRef({});

  useEffect(() => {
    if (refFirstRender.current) {
      if (arrSelected.length) {
        refFirstRender.current = false;
      }
      return;
    };
    callbackChangeSelected(arrSelected);
  }, [arrSelected]);

  useEffect(() => {
    setSelected(arrFilters.map(
      strName => ({
        Name: strName,
        Selected: false,
      }),
    ));
  }, [arrFilters]);

  return (
    <motion.div
      className='col-filters'
      key='col-filters'
      initial={{height: 0, opacity: 0}}
      animate={{height: 'auto', opacity: 1}}
      exit={{height: 0, opacity: 0}}
      transition={{
        duration: 0.4,
        type: 'tween'
      }}
    >
      {arrSelected.length &&
        <div className='container-helpers'>
          <motion.button
            whileTap={{scale: 0.95}}
            whileHover={{scale: 1.05}}
            onClick={() => {
              const bAnyNotSelected = arrSelected.some(
                ({Selected}) => !Selected,
              );
              if (bAnyNotSelected) {
                setSelected(
                  prev => prev.map(objFilter => ({
                    Name: objFilter.Name,
                    Selected: true,
                  }))
                );
              }
            }}
          >
            Select All
          </motion.button>
          <motion.button
            whileTap={{scale: 0.95}}
            whileHover={{scale: 1.05}}
            onClick={() => {
              const bAnySelected = arrSelected.some(
                ({Selected}) => Selected,
              );
              if (bAnySelected) {
                setSelected(
                  prev => prev.map(objFilter => ({
                    Name: objFilter.Name,
                    Selected: false,
                  })),
                );
              }
            }}
          >
            Remove All
          </motion.button>
        </div>
      }
      <div className='container-filters'>
        {arrSelected.map(objFilter => {
          return (
            <Fragment key={objFilter.Name}>
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.button
                  key={`${objFilter.Name}-${objFilter.Selected}`}
                  className={`${objFilter.Selected ? 'selected' : ''}`}
                  whileTap={{scale: 0.95}}
                  whileHover={{scale: 1.05}}
                  custom={objFilter.Selected}
                  initial={bSelected => ({
                    scale: 0.2,
                    rotateZ: bSelected ? -360 : 360,
                    backgroundColor: !bSelected ? '#be87c9' : '#1e1a3b',
                    color: !bSelected ? '#1e1a3b' : '#ee82ee',
                  })}
                  animate={{
                    rotateZ: 0,
                    scale: 1,
                    backgroundColor: objFilter.Selected ? '#be87c9' : '#1e1a3b',
                    color: objFilter.Selected ? '#1e1a3b' : '#ee82ee',
                    transition: {duration: 0.1, type: 'tween'}
                  }}
                  exit={bSelected => ({
                    rotateZ: bSelected ? -360 : 360,
                    scale: 0.2,
                    backgroundColor: !bSelected ? '#be87c9' : '#1e1a3b',
                    color: !bSelected ? '#1e1a3b' : '#ee82ee',
                  })}
                  onClick={() => {
                    if (objDisabledBtns.current[objFilter.Name]) {
                      return;
                    };

                    const arrNewSelected = arrSelected.map(objCurrFilter => {
                      if (objCurrFilter.Name === objFilter.Name) {
                        objDisabledBtns.current[objFilter.Name] = true;
                        setTimeout(
                          () => objDisabledBtns.current[objFilter.Name] = false,
                          900,
                        );

                        return {
                          ...objCurrFilter,
                          Selected: !objCurrFilter.Selected,
                        };
                      }
                      return {...objCurrFilter};
                    });

                    setSelected(arrNewSelected);
                  }}
                >
                  {objFilter.Name}
                </motion.button>
              </AnimatePresence>
            </Fragment>
          );
        })}
      </div>
    </motion.div>
  );
});
